<template>
	<v-dialog v-model="dialogLogin" persistent max-width="500px">
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12">
				<v-card class="elevation-20">
					<v-toolbar color="primary" dark flat>
						<v-toolbar-title>{{ $t('login') }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="close">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>

					<v-card-text>
						<v-form ref="form" lazy-validation>
							<v-text-field
								:label="$t('email')"
								name="email"
								prepend-icon="mdi-account"
								type="email"
								v-model="email"
								:rules="emailRules"
								required
							></v-text-field>

							<v-text-field
								id="password"
								:label="$t('password')"
								name="password"
								prepend-icon="mdi-lock"
								type="password"
								v-model="password"
								:rules="passwordRules"
								required
							></v-text-field>

							<!-- Login error -->
							<v-alert v-model="loginError" type="error" dense dismissible>
								{{ $t('loginError') }}
							</v-alert>
						</v-form>

						<!-- Loader -->
						<div v-if="isLoading" class="text-center">
							<v-progress-circular
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
					</v-card-text>

					<v-card-actions class="pa-5">
						<v-btn color="primary" large block @click="onSubmit">{{
							$t('login')
						}}</v-btn>
					</v-card-actions>

					<v-card-actions>
						<div class=" ml-4 mb-4">
							{{ $t('dontHaveAccount') }}
							<a @click.prevent="$emit('not-registered')">{{
								$t('register')
							}}</a>
						</div>

						<v-spacer></v-spacer>
						<a
							class="forgot-pass mr-4 mb-4"
							@click.prevent="$emit('forgot-pass')"
							>{{ $t('forgotYourPassword') }}</a
						>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'Login',

	props: {
		dialogLogin: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	data() {
		return {
			email: '',
			password: '',
			isLoading: false,
			loginError: false,
			emailRules: [
				v => !!v || this.$i18n.t('requiredField'),
				v => /.+@.+\..+/.test(v) || this.$i18n.t('validEmail'),
			],
			passwordRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	methods: {
		...mapActions('auth', ['login']),
		close() {
			this.$emit('close-login');
		},
		reset() {
			this.$refs.form.resetValidation();
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;
			const payload = {
				email: this.email,
				password: this.password,
			};

			try {
				const res = await this.login(payload);

				this.close();
				this.$router.push('/pets');
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.isLoading = false;
				this.loginError = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.forgot-pass {
	color: #f0ae1d;
}
</style>
